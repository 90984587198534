import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

//
import enLocales from './en.json';
import ltLocales from './lt.json';
import ruLocales from './ru.json';
import lvLocales from './lv.json';
import eeLocales from './ee.json';

// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      lt: { translations: ltLocales },
      lv: { translations: lvLocales },
      ee: { translations: eeLocales },
      ru: { translations: ruLocales },
    },
    lng: localStorage.getItem('i18nextLng') || 'lt',
    fallbackLng: 'lt',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
