import SignaturePad from 'react-signature-pad-wrapper';
import React, { useRef, useState, useEffect, ChangeEvent, MutableRefObject } from 'react';

import { Box, Button, Dialog, useTheme, TextField, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import { CroppedCanvas } from 'src/@types/documents/types';
import { setSignImage, setFinalSignSize } from 'src/redux/slices/signaturePadReqModel';

import INK_COLOR from 'src/sections/forms/elements/signature-utils/inkColor';
import cropSignatureCanvas from 'src/sections/forms/elements/signature-utils/cropSignatureCanvas';
import writeNameOnTheCanvas from 'src/sections/forms/elements/signature-utils/writeNameOnTheCanvas';
import combineNameWithSignature from 'src/sections/forms/elements/signature-utils/combineNameWithSignature';

type DocumentSignaturePadDialogProps = {
  isSignaturePadDialogOpen: boolean;
  setIsSignaturePadDialogOpen: (isSignaturePadDialogOpen: boolean) => void;
};

const DocumentSignaturePadDialog: React.FC<DocumentSignaturePadDialogProps> = ({ isSignaturePadDialogOpen, setIsSignaturePadDialogOpen }) => {
  const { t } = useLocales();
  const [addedEventListener, setAddedEventListener] = useState(false);
  const [isEmptySignature, setIsEmptySignature] = useState(true);
  const canvas = useRef() as MutableRefObject<any>;
  const theme = useTheme();
  const [name, setName] = useState<string | undefined>(undefined);
  const [surname, setSurname] = useState<string | undefined>(undefined);

  const handleClose = () => {
    setIsSignaturePadDialogOpen(false);
  };

  const handleClear = () => {
    canvas.current.clear();
    setIsEmptySignature(true);
  };

  const handleEndStroke = () => {
    setIsEmptySignature(canvas.current.isEmpty());
  };

  const handleContinue = () => {
    const untrimmedCanvas = canvas.current.signaturePad.canvas as HTMLCanvasElement;
    const croppedSignature = cropSignatureCanvas(untrimmedCanvas);
    const croppedName = writeNameOnTheCanvas(`${name} ${surname}`, croppedSignature.height);

    if (croppedName) {
      const combinedCanvas = combineNameWithSignature(croppedName, croppedSignature);

      const newCanvas: CroppedCanvas = {
        image: combinedCanvas.toDataURL(),
        width: combinedCanvas.width,
        height: combinedCanvas.height,
      };

      const sizeK = Math.floor(newCanvas.height / 60);

      const defaultSize = {
        height: 60,
        width: Math.round(newCanvas.width / sizeK),
      };

      setSignImage(newCanvas.image, newCanvas.width, newCanvas.height);
      setFinalSignSize(defaultSize.width, defaultSize.height);
    } else {
      const newCanvas: CroppedCanvas = {
        image: croppedSignature.toDataURL(),
        width: croppedSignature.width,
        height: croppedSignature.height,
      };

      setSignImage(newCanvas.image, newCanvas.width, newCanvas.height);
    }

    setIsSignaturePadDialogOpen(false);
  };
  useEffect(() => {
    if (name && !addedEventListener && canvas.current && canvas.current.signaturePad) {
      canvas.current.signaturePad.addEventListener('endStroke', handleEndStroke);

      setAddedEventListener(true);
    }
  }, [name]);

  return (
    <Dialog open={isSignaturePadDialogOpen} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle>{t('forms.addSignatureToSign')}</DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}> {t('documents.writeDrawClickContinue')}</DialogContentText>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 3,
            }}
          >
            <TextField
              fullWidth
              size='small'
              label={t('shared.fields.firstName')}
              value={name}
              variant='outlined'
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              id="9i2feut6"
            />

            <TextField
              fullWidth
              size='small'
              label={t('shared.fields.lastName')}
              value={surname}
              variant='outlined'
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSurname(event.target.value);
              }}
            />
          </Box>

          <Box sx={{ border: `1px solid ${theme.palette.grey[300]}` }}>
            <SignaturePad
              ref={canvas}
              options={{
                minWidth: 2,
                maxWidth: 4,
                penColor: INK_COLOR,
              }}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: 3,
          pb: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            gap: 3,
          }}
        >
          <Button disabled={!name || !surname || isEmptySignature} variant='contained' onClick={handleContinue} id="b2pe340j">
            {t('shared.buttons.continue')}
          </Button>

          <Button variant='outlined' onClick={handleClose} id="mz5nnxqa">
            {t('shared.buttons.cancel')}
          </Button>
        </Box>

        <Button disabled={isEmptySignature} variant='text' onClick={handleClear} id="3anq0rc3">
          {t('shared.buttons.clear')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentSignaturePadDialog;
