import dayjs, { Dayjs } from 'dayjs';
import React, { useState, useEffect } from 'react';

import { Box } from '@mui/system';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers-pro';

import ElementLabel from './ElementLabel';
import useLocales from '../../../hooks/useLocales';
import './elements-vanilla-mobile/address-book-mobile.scss';
import { setErrorList } from '../../../redux/slices/formBuilder';
import validateIsEmail from '../../../@types/forms/utils/validateIsEmail';
import validateIsNumber from '../../../@types/forms/utils/validateIsNumber';
import validateIsRequired from '../../../@types/forms/utils/validateIsRequired';
import { FormElementValidationType } from '../../../@types/forms/enums/FormElementValidationType';
import { ElementProps, ErrorsListProps, ElementValidationProps } from '../../../@types/forms/types';

//------------------------------------------------------------------

type DateElementProps = {
  el: ElementProps;
  pageNumberScale: number;
  isFormFillerPage: boolean;
  elementContentValue?: Dayjs | null;
  handleDateChange?: (el: ElementProps, newValue: dayjs.Dayjs | null) => void;
};

const DateElement: React.FC<DateElementProps> = ({
  el,
  pageNumberScale,
  isFormFillerPage,
  elementContentValue,
  handleDateChange,
}) => {
  const { t } = useLocales();
  const defaultDateValue = dayjs();
  const [value, setValue] = useState<string | undefined>(defaultDateValue.format('YYYY-MM-DD'));
  const [elementErrorsList, setElementErrorsList] = useState<ErrorsListProps[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const useStyles = makeStyles({
    root: {
      fontFamily: `${el.font}`,
      fontWeight: el.textStyle.includes('Bold') ? 'bold' : 'normal',
      fontStyle: el.textStyle.includes('Italic') ? 'italic' : 'normal',
      textDecoration: el.textDecoration === 1 ? 'underline' : '',
      color: el.color,
      fontSize: `${el.fontSize * pageNumberScale}px`,
      lineHeight: `${el.fontSize * pageNumberScale * 1.2}px`,
      textAlign: el.textAlignment === 0 ? 'left' : el.textAlignment === 1 ? 'center' : 'right',
      width: '100%',
      wordWrap: 'break-word',
      border: 'none',
      margin: 0,
      padding: 0,
    },
  });

  const classes = useStyles();

  useEffect(() => {
    const data: ElementValidationProps = { el, errorMessage: '', value, elementErrorsList };
    const newErrorList: ErrorsListProps[] = [];

    if (el.required) {
      const newError = validateIsRequired({
        ...data,
        errorMessage: t('forms.validationErrorMessage.fieldRequired'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    if (el?.validations?.value === FormElementValidationType.OnlyNumbers) {
      const newError = validateIsNumber({
        ...data,
        errorMessage: t('forms.validationErrorMessage.notNumber'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    if (el?.validations?.value === FormElementValidationType.Email) {
      const newError = validateIsEmail({
        ...data,
        errorMessage: t('forms.validationErrorMessage.invalidEmailFormat'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    setElementErrorsList(newErrorList);
  }, [value]);

  useEffect(() => {
    if (elementErrorsList) {
      setErrorList({ elementID: el.id, errors: elementErrorsList });
    }
  }, [elementErrorsList]);

  useEffect(() => {
    if (elementContentValue) {
      setValue(elementContentValue.format('YYYY-MM-DD'));
    }
  }, []);

  return (
    <>
      {isFormFillerPage && (
        <Box
          sx={{ fontSize: `${el.fontSize * pageNumberScale}px`, width: el.width * pageNumberScale }}
          className="date-range-bg"
          id={el.id}
        >
          <DatePicker
            disabled={el.isChild}
            key={el.id}
            open={isOpen}
            slotProps={{
              textField: {
                variant: 'standard',
                size: 'small',
                fullWidth: true,
                error: elementErrorsList.length > 0,
                helperText: elementErrorsList.length > 0 ? elementErrorsList[0].errorMessage : null,
                inputProps: { className: classes.root },
                onClick: () => setIsOpen(true),
              },
            }}
            slots={{
              openPickerButton: () => null,
            }}
            value={elementContentValue || defaultDateValue}
            onChange={(newValue) => {
              if (handleDateChange) {
                handleDateChange(el, newValue);
                setValue(newValue?.format('YYYY-MM-DD'));
                setIsOpen(false);
              } else {
                setValue(defaultDateValue.format('YYYY-MM-DD'));
              }
            }}
          />
        </Box>
      )}
      
      {!isFormFillerPage && (
        <ElementLabel el={el} label="YYYY-MM-DD" pageNumberScale={pageNumberScale} />
      )}
    </>
  );
};

export default DateElement;
