import fileDownload from 'js-file-download';
import React, { useState, useEffect } from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Paper, Checkbox, FormGroup, FormControlLabel } from '@mui/material';

import { Containers } from 'src/@types/fileView/enums/fileExtensions';

import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import useResponsive from '../../hooks/useResponsive';
import useNotifications from '../../hooks/useNotifications';
import { bulkDownload } from '../../services/dlxDriveService';
import { ModuleTypes } from '../../@types/module/enums/moduleTypes';
import ModuleCheck from '../../components/access-checks/ModuleCheck';
import DLXDriveBulkMoveDialog from './dialogs/DLXDriveBulkMoveDialog';
import DLXDriveBulkShareDialog from './dialogs/DLXDriveBulkShareDialog';
import DLXDriveBulkDeleteDialog from './dialogs/DLXDriveBulkDeleteDialog';
import { FolderActions } from '../../@types/dlx-drive/enums/FolderActions';
import DLXDriveBulkApproveDialog from './dialogs/DLXDriveBulkApproveDialog';
import { DLXDriveBulkDownloadModelType } from '../../@types/dlx-drive/types';
import { DocumentStatuses } from '../../@types/documents/enums/documentStatuses';
import DLXDriveBulkActionComponentButton from './DLXDriveBulkActionComponentButton';
import { addStopLoadingState, clearCheckedItemsState } from '../../redux/slices/dlxDrive';

// ----------------------------------------------------------------

const DLXDriveBulkActionComponent: React.FC = () => {
  const { t } = useLocales();
  const { checkedItems, cardWidth } = useSelector((state) => state.dlxDrive);
  const [checked, setChecked] = useState(false);
  const [isBulkApproveDialogOpen, setIsBulkApproveDialogOpen] = useState(false);
  const [isBulkShareDialogOpen, setIsBulkShareDialogOpen] = useState(false);
  const [isBulkDeleteDialogOpen, setIsBulkDeleteDialogOpen] = useState(false);
  const [isBulkMoveDialogOpen, setIsBulkMoveDialogOpen] = useState(false);

  const [isBulkDownloadDisabled, setIsBulkDownloadDisabled] = useState<boolean>(false);
  const [isBulkShareDisabled, setIsBulkShareDisabled] = useState<boolean>(false);
  const [isBulkMoveDisabled, setIsBulkMoveDisabled] = useState<boolean>(false);
  const [isBulkDeleteDisabled, setIsBulkDeleteDisabled] = useState<boolean>(false);

  const theme = useTheme();
  const { enqueueSuccess, enqueueError } = useNotifications();

  const isMobile = useResponsive('only', 'xs');
  const isLight = theme.palette.mode === 'light';

  const handleDownloadClick = async () => {
    const model: DLXDriveBulkDownloadModelType = {
      folderIds: checkedItems.filter((item) => item.isFolder).map((item) => item.id),
      documentIds: checkedItems.filter((item) => !item.isFolder).map((item) => item.id),
    };

    try {
      const file = await bulkDownload(model);

    // eslint-disable-next-line prefer-destructuring
      let fileName = file.fileName;
    
      const isContainer = Containers.some(item => checkedItems[0].name.includes(item));

      if(checkedItems.length === 1 && isContainer){
        fileName = checkedItems[0].name;
      }
      fileDownload(file.file, fileName, file.mime);
      enqueueSuccess('dlxDrive.notifications.downloadSuccess');
      clearCheckedItemsState();
    } catch (error) {
      enqueueError('dlxDrive.errors.downloadError');
    } finally {
      addStopLoadingState();
    }
  };

  const checkBulkDownloadRights = () => {
    setIsBulkDownloadDisabled(
      checkedItems.some(
        (item) =>
          (item.isFolder && item.actions.indexOf(FolderActions.FolderDownload) === -1) ||
          (!item.isFolder && item.actions.indexOf(FolderActions.FileShare) === -1)
      )
    );
  };

  const checkBulkShareRights = () => {
    setIsBulkShareDisabled(
      checkedItems.some(
        (item) =>
          (item.isFolder && item.actions.indexOf(FolderActions.FolderShare) === -1) ||
          (!item.isFolder && item.actions.indexOf(FolderActions.FileShare) === -1)
      )
    );
  };

  const checkBulkMoveRights = () => {
    setIsBulkMoveDisabled(
      checkedItems.some(
        (item) =>
          (item.isFolder && item.actions.indexOf(FolderActions.FolderMove) === -1) ||
          (!item.isFolder && item.actions.indexOf(FolderActions.FileMove) === -1)
      )
    );
  };

  const checkBulkDeleteRights = () => {
    setIsBulkDeleteDisabled(
      checkedItems.some(
        (item) =>
          (item.isFolder && item.actions.indexOf(FolderActions.Delete) === -1) ||
          (!item.isFolder && item.actions.indexOf(FolderActions.FileDelete) === -1)
      )
    );
  };

  useEffect(() => {
    if (checkedItems.length > 0) {
      setChecked(true);

      checkBulkDownloadRights();
      checkBulkShareRights();
      checkBulkMoveRights();
      checkBulkDeleteRights();
    } else {
      setChecked(false);
    }
  }, [checkedItems]);

  return (
    <>
      <Paper
        sx={{
          width: `${cardWidth}px`,
          height: isMobile ? '60px' : '80px',
          px: isMobile ? 2 : 4,
          position: 'fixed',
          bottom: '10px',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          borderRadius: '16px',
          backgroundColor: isLight ? theme.palette.grey[800] : theme.palette.grey[50],
        }}
        elevation={3}
      >
        <Box>
          <FormGroup>
            <FormControlLabel
              sx={{
                color: isLight ? theme.palette.grey[50] : theme.palette.grey[800],
                flexWrap: 'nowrap',
              }}
              control={<Checkbox checked={checked} onClick={clearCheckedItemsState} id="wpITci" />}
              label={`${checkedItems.length} ${isMobile ? '' : t('dlxDrive.bulkActions.selected')}`}
            />
          </FormGroup>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            gap: isMobile ? 1 : 3,
          }}
        >
          <DLXDriveBulkActionComponentButton
            isDisabled={isBulkDownloadDisabled}
            icon="download"
            tooltipTitle={t('dlxDrive.bulkActions.download')}
            onClick={handleDownloadClick}
            id="nSxgZy"
          />

          <ModuleCheck module={ModuleTypes.MassApprovalModule}>
            <DLXDriveBulkActionComponentButton
              isDisabled={
                checkedItems.filter(
                  (item) => item.status && item.status === DocumentStatuses.WaitingForMeApprove
                ).length === 0
              }
              icon="approve"
              tooltipTitle={t('dlxDrive.bulkActions.approve')}
              onClick={() => setIsBulkApproveDialogOpen(true)}
              id="ASKl6Q"

            />
          </ModuleCheck>

          <DLXDriveBulkActionComponentButton
            isDisabled={isBulkShareDisabled}
            icon="share"
            tooltipTitle={t('dlxDrive.bulkActions.share')}
            onClick={() => setIsBulkShareDialogOpen(true)}
            id="YxcFwP"
          />

          <DLXDriveBulkActionComponentButton
            isDisabled={isBulkMoveDisabled}
            icon="move"
            tooltipTitle={t('dlxDrive.bulkActions.move')}
            onClick={() => setIsBulkMoveDialogOpen(true)}
            id="YsUVrU"
          />

          <DLXDriveBulkActionComponentButton
            isDisabled={isBulkDeleteDisabled}
            icon="delete"
            tooltipTitle={t('dlxDrive.bulkActions.delete')}
            onClick={() => setIsBulkDeleteDialogOpen(true)}
            id="2qr0wv"
          />
        </Box>
      </Paper>

      <DLXDriveBulkApproveDialog
        isBulkApproveDialogOpen={isBulkApproveDialogOpen}
        setIsBulkApproveDialogOpen={setIsBulkApproveDialogOpen}
      />

      <DLXDriveBulkShareDialog
        isBulkShareDialogOpen={isBulkShareDialogOpen}
        setIsBulkShareDialogOpen={setIsBulkShareDialogOpen}
      />

      <DLXDriveBulkDeleteDialog
        isBulkDeleteDialogOpen={isBulkDeleteDialogOpen}
        setIsBulkDeleteDialogOpen={setIsBulkDeleteDialogOpen}
      />

      <DLXDriveBulkMoveDialog
        isBulkMoveDialogOpen={isBulkMoveDialogOpen}
        setIsBulkMoveDialogOpen={setIsBulkMoveDialogOpen}
      />
    </>
  );
};

export default DLXDriveBulkActionComponent;
