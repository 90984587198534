import * as Yup from 'yup';
import { useEffect, ChangeEvent } from 'react';
import { Form, useFormik, FormikProvider } from 'formik';

import { Box, Stack, TextField } from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useFormTranslate from 'src/hooks/useFormTranslate';

import { ValidationPatterns } from 'src/utils/constants/validationPatterns';

import { DocumentSignatureType } from 'src/@types/documents/enums/documentSignatureTypes';

import MuiPhoneNumber from 'src/components/country-code-picker';

type InitialValues = {
  phone: string;
  inn: string;
  position: string;
  subdivision: string;
};

type DocumentMobileIdSignProps = {
  submitTrigger: any;
  submitValues: (value: any) => void;
  isNotPdf: boolean;
};

export default function DocumentMobileIdSign({
  submitTrigger,
  submitValues,
  isNotPdf,
}: DocumentMobileIdSignProps) {
  const { t } = useLocales();

  useEffect(() => {
    if (submitTrigger) {
      submitForm();
    }
  }, [submitTrigger]);

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .required(t('components.mobileId.phoneRequired'))
      .min(11, t('components.mobileId.phoneLength')),
    inn: Yup.string()
      .required(t('components.mobileId.nationalNumberRequired'))
      .min(11, t('components.mobileId.nationalNumberLength')),
    position: Yup.string().when([], {
      is: () => isNotPdf,
      then: Yup.string().required(t('shared.errors.positionRequired')),
      otherwise: Yup.string().notRequired(),
    }),
    subdivision: Yup.string().when([], {
      is: () => isNotPdf,
      then: Yup.string().required(t('shared.errors.subdivisionRequired')),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      phone: '',
      inn: '',
      position: '',
      subdivision: '',
    },
    validationSchema,

    onSubmit: (values) => {
      if (isValid) {
        submitValues({ ...values, signatureType: DocumentSignatureType.MobileId });
        resetForm();
      }
    },
  });

  const { errors, touched, isValid, submitForm, resetForm, setTouched, getFieldProps } = formik;

  useFormTranslate(formik);

  const handlePhoneChange = (e: any) => {
    const phone = e.toString().replace(ValidationPatterns.tel, '');
    formik.setFieldValue('phone', phone);
  };

  const handleInnChange = (e: any) => {
    formik.setFieldValue('inn', ValidationPatterns.presonalCodeValueFilter(e.target.value));
  };

  const handlePositionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const position = e.target.value;
    formik.setFieldValue('position', position);
  };
  const handleSubdivisionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const subdivision = e.target.value;
    formik.setFieldValue('subdivision', subdivision);
  };

  return (
    <Box sx={{ mb: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate>
            <Stack spacing={3} id='gtp0lee0'>
              <input type="submit" hidden />

              {isNotPdf && (
                <>
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={handlePositionChange}
                    value={formik.values.position}
                    label={t('shared.fields.position')}
                    error={Boolean(touched.position && errors.position)}
                    helperText={touched.position && errors.position}
                    size="small"
                    id="huuyLL"
                  />

                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={handleSubdivisionChange}
                    value={formik.values.subdivision}
                    label={t('shared.fields.subdivision')}
                    error={Boolean(touched.subdivision && errors.subdivision)}
                    helperText={touched.subdivision && errors.subdivision}
                    size="small"
                    id="fYeNUn"
                  />
                </>
              )}

              <MuiPhoneNumber
                defaultCountry="lt"
                size="small"
                variant="outlined"
                value={formik.values.phone}
                onChange={handlePhoneChange}
                onlyCountries={['lt', 'lv', 'ee']}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
                label={t('components.mobileId.mobileId')}
                id="L9bCYA"
              />

              <TextField
                variant="outlined"
                fullWidth
                onChange={handleInnChange}
                value={formik.values.inn}
                label={t('components.mobileId.nationalNumber')}
                error={Boolean(touched.inn && errors.inn)}
                helperText={touched.inn && errors.inn}
                inputProps={{ maxLength: 12, inputMode: 'numeric' }}
                size="small"
                id="L1tbyB"
              />
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
  );
}
