import React from 'react';

import { Box } from '@mui/system';
import { useTheme, Typography } from '@mui/material';

import { ElementProps } from '../../../@types/forms/types';
import convertTextAlignment from '../../../utils/forms/convertTextAlignment';
import ElementLabelMobile from './elements-vanilla-mobile/ElementLabelMobile';

//-------------------------------------------

type ElementLabelProps = {
  el: ElementProps;
  label: string | undefined;
  pageNumberScale: number;
  asChildEl?: boolean;
  isMobileView?: boolean;
  isStatic?: boolean;
};

const ElementLabel: React.FC<ElementLabelProps> = ({ el, label, pageNumberScale, asChildEl, isMobileView, isStatic }) => {
  let strings: string[] = [];
  const theme = useTheme();

  if (label && label.length > 0) {
    strings = label.split('\n');
  } else {
    strings.push(el.label);
  }

  return (
    <>
     { !isMobileView && (
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        width: '100%', 
        backgroundColor: asChildEl || isStatic ? '' : theme.palette.info.lighter, 
        borderRadius: 1, 
        py: 0.5 
      }} id={`${el.id}-is-mobile-view`}>
        {strings &&
          strings.map((string, i) => (
            <Typography
              key={`${el.id}-${i}`}
              sx={{
                fontFamily: `${el.font}`,
                fontWeight: el.textStyle.includes('Bold') ? 'Bold' : 'Regular',
                fontStyle: el.textStyle.includes('Italic') ? 'italic' : 'normal',
                textDecoration: el.textDecoration === 1 ? 'underline' : '',
                color: el.color,
                fontSize: `${el.fontSize * pageNumberScale}px`,
                lineHeight: `${el.fontSize * pageNumberScale * 1.2}px`,
                textAlign: convertTextAlignment(el.textAlignment),
                width: '100%',
                wordWrap: 'break-word',
                m: 0,
                p: 0,
                // transform: `rotate(${el.rotate}deg)`,
              }}
            >
              {string}
            </Typography>
          ))}
      </Box>
    )}

    {
      isMobileView && <ElementLabelMobile el={el} label={label} pageNumberScale={pageNumberScale} isStatic />
    }
    </>
  
  );
};

export default ElementLabel;
