import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useParams, useLocation, useNavigate } from 'react-router';

import { useSelector } from '../../redux/store';
import { getDocument } from '../../redux/slices/document';
import LoadingScreen from '../../components/LoadingScreen';
import { GetSessionData, getSigningSession } from '../../redux/slices/signatureSession';
import { DocumentSignatureType } from '../../@types/documents/enums/documentSignatureTypes';

const EparakstsMobileCallback = () => {
  const { document } = useSelector((state) => state.document);
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('sessionId');
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const getSigningDocument = async (id: number) => {
    await getDocument(id);
  };

  useEffect(() => {
    if (document && sessionId) {
      try {
        const getSessionData = {
          documentId: document.id,
          signatureType: DocumentSignatureType.EParaksts,
          documentName: document.name,
          documentExtension: document.extension,
        } as GetSessionData;

        getSigningSession(sessionId, getSessionData);
      } catch (error: any) {
        throw error;
      } finally {
        <LoadingScreen />;

        setTimeout(() => {
          const url = location.pathname.replace('eparaksts-mobile/callback', '');
          navigate(url);
        }, 14000);
      }
    }
  }, [document, sessionId]);

  useEffect(() => {
    let docId;

    if (typeof id === 'string') {
      docId = parseInt(id, 10);
    }

    if (docId && !Number.isNaN(docId)) {
      getSigningDocument(docId);
    }
  }, [id]);

  return <LoadingScreen />;
};

export default EparakstsMobileCallback;
