import cropSignatureCanvas from './cropSignatureCanvas';

// ---------------------------------------------------

const combineNameWithSignature = (name: HTMLCanvasElement, signature: HTMLCanvasElement) => {
  const canvas = document.createElement('canvas');
  canvas.width = name.width + signature.width + 100;
  canvas.height = signature.height + 100;

  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.drawImage(name, 10, Math.round(signature.height / 2 - name.height / 2 + 50));
    ctx.drawImage(signature, name.width + 20, 50);

    return cropSignatureCanvas(canvas);
  }

  return cropSignatureCanvas(signature);
};

export default combineNameWithSignature;
