import { useState, useEffect } from 'react';

import { Box, Menu, Tooltip, useTheme, TableCell } from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { SortedTableCell } from 'src/@types/shared/sortedTableCell';
import { FilteringItemModel, FilteringValueType } from 'src/@types/filtering/types';

import Iconify from 'src/components/Iconify';

//--------------------------------------------------------

type SortingState = {
  column: number | null;
  type: number;
};

type SortedHeaderProps = {
  columns: SortedTableCell[];
  definedFilters?: FilteringItemModel[];
  handleSortingChange?: (column: number | null, sortDescending: boolean) => void;
  handleFiltersChange?: (filter: {}) => void;
  refreshTrigger?: number;
  isDownload: boolean;
  isCardView?: boolean;
  onDownloadFile?: () => void;
};

export default function SortedHeader({
  columns,
  definedFilters,
  handleSortingChange,
  handleFiltersChange,
  refreshTrigger,
  isDownload,
  isCardView,
  onDownloadFile,
}: SortedHeaderProps) {
  const { t } = useLocales();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<{ anchor: HTMLElement; id: string }>();
  const [sorting, setSorting] = useState({ column: null, type: -1 } as SortingState);
  const [filters, setFilters] = useState<FilteringItemModel[]>([]);
  const [columnId, setColumnId] = useState<string>();
  const [columnName, setColumnName] = useState<string>();
  const [activeItemSt, setActiveItemSt] = useState<string[]>([]);
  const isMobile = useResponsive('only', 'xs');
  const isLight = theme.palette.mode === 'light';

  useEffect(() => {}, [columnId, filters, columnName]);

  useEffect(() => {
    if (definedFilters) {
      setFilters([...definedFilters]);
    }
  }, [definedFilters]);

  const handleChange = (
    column: number | null,
    columnId: string | null,
    columnName: string | null,
    colFilterName: string | null
  ) => {
    if (columnName && columnId) {
      setColumnName(columnName);
      setColumnId(columnId);
    }

    if (sorting.column !== column) {
      setSorting({ column, type: 0 });
      sortAddedColumn(columnId, columnName, colFilterName);
    } else {
      let type = sorting.type + 1;

      if (type > 1) {
        type = -1;
        column = null;
      }

      setSorting({ column, type });
      sortAddedColumn(columnId, columnName, colFilterName);
    }
  };

  const sortAddedColumn = (
    columnId: string | null,
    columnName: string | null,
    colFilterName: string | null
  ) => {
    if (columnId !== null && columnName !== null && colFilterName !== null) {
      handleFilterChange('formColumnFilter', { columnId, formName: columnName, value: '' });
    }
  };

  const handleAddColumnData = (colName: string, colId: string) => {
    setColumnName(colName);
    setColumnId(colId);
  };

  const handleFilterChange = (name: string, value?: FilteringValueType) => {
    const newFilters = filters.filter((f) => f.name !== name);

    if (value) {
      newFilters.push({ name, value });
    }
    setFilters(newFilters);
  };

  useEffect(() => {
    if (handleSortingChange) {
      handleSortingChange(sorting.column, sorting.type === 1);
    }
  }, [sorting.type, sorting.column]);

  useEffect(() => {
    const filterObject = filters.reduce((o, k) => ({ ...o, [k.name]: k.value }), {});

    if (handleFiltersChange) {
      handleFiltersChange(filterObject);
    }
  }, [filters]);

  useEffect(() => {
    setFilters([]);
    setSorting({ column: null, type: -1 });
    setActiveItemSt([]);
  }, [refreshTrigger]);

  const getSortDirection = (column: number, actionColumnId: string | null) => {
    if (actionColumnId && actionColumnId === columnId) {
      // for added columns
      if (sorting.column === 4 && sorting.column === column) {
        switch (sorting.type) {
          case 0:
            return <Iconify icon="eva:arrow-upward-outline" sx={{ cursor: 'pointer', ml: 1 }} />;
          case 1:
            return <Iconify icon="eva:arrow-downward-outline" sx={{ cursor: 'pointer', ml: 1 }} />;
          default:
            return null;
        }
      }
    } else {
      // for default columns
      if (sorting.column !== 4 && sorting.column === column) {
        switch (sorting.type) {
          case 0:
            return <Iconify icon="eva:arrow-upward-outline" sx={{ cursor: 'pointer', ml: 1 }} />;
          case 1:
            return <Iconify icon="eva:arrow-downward-outline" sx={{ cursor: 'pointer', ml: 1 }} />;
          default:
            return null;
        }
      }
    }

    return (
      <Iconify
        icon="eva:swap-outline"
        sx={{ transform: 'rotate(90deg)', cursor: 'pointer', ml: 1 }}
        className="default-sorting-icon"
      />
    );
  };

  const getSortableIcon = (
    column: number,
    columnId: string | null,
    columnName: string | null,
    colFilterName: string | null
  ) => (
    <Box
      sx={{ display: 'flex', alignItems: 'center' }}
      onClick={() => handleChange(column, columnId, columnName, colFilterName)}
    >
      {getSortDirection(column, columnId)}
    </Box>
  );

  const handleOpenFilter = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    setAnchorEl({ anchor: e.currentTarget, id });
  };

  const getTitle = (title: string | JSX.Element) => {
    if (typeof title === 'string') {
      if (title !== 'shared.fields.signatures') {
        return <>{t(title)}</>;
      }
      return <Box sx={{ ml: -1 }}>{t(title)}</Box>;
    }

    return <>{title}</>;
  };

  const isFilterSortActive = (
    filterName: string | undefined,
    sortingKey: number | undefined | null,
    id: string | undefined
  ) => {
    const activeItem = { filterName, sortingKey, id };
    const isFilter = filters.find((f) => f.name === filterName)?.value !== undefined;
    const isSort =
      sortingKey !== null &&
      sortingKey !== undefined &&
      sortingKey === sorting.column &&
      sorting.type !== -1;

    // return isFilter || isSort;
    // return isFilter;
    // return id ? activeItemSt.includes(id) : false || isFilter || isSort;
    return false;
  };

  const handleActiveField = (col: SortedTableCell) => {
    if (activeItemSt.length > 0 && col.id && activeItemSt.includes(col.id)) {
      setActiveItemSt(activeItemSt.filter((item) => item !== col.id));
    } else if (col.id) {
      setActiveItemSt([...activeItemSt, col.id]);
    }
  };

  return (
    <>
      {!isCardView && (
        <>
          {isDownload && (
            <TableCell sx={{ backgroundColor: isLight ? theme.palette.grey[400] : theme.palette.grey[700] }}>
              <Box sx={{ p: 1 }} />
            </TableCell>
          )}

          {columns.map((col, index) => (
            <TableCell
              key={index}
              align={index === columns.length - 1 ? 'right' : 'left'}
              sx={{
                MozUserSelect: 'none',
                WebkitUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none',
                ...(!!col.filter && { cursor: 'pointer' }),
                backgroundColor: isLight ? theme.palette.grey[400] : theme.palette.grey[700],
                color: isLight ? theme.palette.grey[700] : theme.palette.grey[300],
                px: 0.5
              }}
            >
              <Tooltip title={col.name !== undefined ? col.name : ''} placement="top-start">
                <Box sx={{ display: 'flex'}} onClick={() => handleActiveField(col)}>
                  <Box
                    display="flex"
                    alignItems="baseline"
                    sx={{
                      px: columns.length - 1 === index ? 0 : 1,
                      backgroundColor: isFilterSortActive(col.filter?.name, col.sortingKey, col.id)
                        ? theme.palette.grey[400]
                        : undefined,
                      borderRadius: '6px'
                    }}

                    // onClick={() => col.id !== undefined && col.name !== undefined && handleAddColumnData(col.name, col.id)}
                  >
                    {!!col.filter && (
                      <>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center' }}
                          id={`filter-button${index}`}
                          onClick={(e) => handleOpenFilter(e, `filter-menu${index}`)}
                        >
                          <Box 
                          sx={{ 
                            whiteSpace: 'pre-wrap'
                          }}>
                              {getTitle(col.title)}
                          </Box>
                          <Iconify
                            icon="eva:chevron-down-fill"
                            sx={{ width: '24px', height: '24px', mr: -1 }}
                          />
                        </Box>
                        <Menu
                          id={`filter-menu${index}`}
                          anchorEl={anchorEl?.anchor}
                          open={anchorEl?.id === `filter-menu${index}`}
                          onClose={() => setAnchorEl(undefined)}
                        >
                          <Box sx={{ p: 2 }}>
                            <col.filter.component
                              key={`${col.title}-${index}`}
                              name={col.filter.name}
                              formName={col.name !== undefined ? col.name : undefined}
                              value={filters.find((f) => f.name === col.filter?.name)?.value}
                              columnId={col.id ? col.id : undefined}
                              onChange={handleFilterChange}
                            />
                          </Box>
                        </Menu>
                      </>
                    )}
                    {!col.filter && (
                      <Box 
                      lang='en'
                      sx={{
                        px: 0, 
                        mx: 0, 
                        maxWidth: col.id === 'id-signatures-no-filter' ? '3rem': 'inherit'
                        }}>
                          {getTitle(col.title)}</Box>)}

                    {col.sortingKey !== undefined &&
                      col.sortingKey !== null &&
                      getSortableIcon(
                        col.sortingKey,
                        col.id ? col.id : null,
                        col.name ? col.name : null,
                        col.filter !== undefined ? col.filter.name : null
                      )}
                  </Box>
                  {isFilterSortActive(col.filter?.name, col.sortingKey, col.id) && (
                    <Box
                      sx={{
                        height: '6px',
                        width: '6px',
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: '50%',
                        ml: '-4px',
                        mt: '-2px',
                      }}
                    />
                  )}
                </Box>
              </Tooltip>
            </TableCell>
          ))}
        </>
      )}

      {isCardView && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: isMobile ? 1.5 : 2,
            width: '100%',
            color: theme.palette.grey[700],
            mb: 2,
            mt: 1,
            fontSize: isMobile ? '14px' : 'inherit'
          }}
        >
          {columns.map((col, index) => {
            if (col.title !== 'shared.fields.signatures') {
              return (
                <Box
                  key={index}
                  sx={{
                    MozUserSelect: 'none',
                    WebkitUserSelect: 'none',
                    msUserSelect: 'none',
                    userSelect: 'none',
                    ...(!!col.filter && { cursor: 'pointer' }),
                    height: '40px',
                    px: isMobile ? 1 : 2,
                    borderRadius: 0.8,
                    fontWeight: 600,
                    color: isLight ? theme.palette.grey[700] : theme.palette.grey[300],
                    backgroundColor: isLight ? theme.palette.grey[400] : theme.palette.grey[700],
                    // backgroundColor: isFilterSortActive(col.filter?.name, col.sortingKey, col.id)
                    //   ? alpha(theme.palette.primary.main, 0.12)
                    //   : theme.palette.grey[300],
                    border: isFilterSortActive(col.filter?.name, col.sortingKey, col.id)
                      ? `1px solid ${theme.palette.primary.main}`
                      : `none`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Tooltip title={col.name !== undefined ? col.name : ''} placement="top-start">
                    <Box
                      sx={{ display: 'flex' }}
                      onClick={() =>
                        col.id !== undefined &&
                        col.name !== undefined &&
                        handleAddColumnData(col.name, col.id)
                      }
                    >
                      {!!col.filter && (
                        <>
                          <Box
                            sx={{ display: 'flex', alignItems: 'center' }}
                            id={`filter-button${index}`}
                            onClick={(e) => handleOpenFilter(e, `filter-menu${index}`)}
                          >
                            <Box sx={{ whiteSpace: 'nowrap' }}>{getTitle(col.title)}</Box>
                            <Iconify
                              icon="eva:chevron-down-fill"
                              sx={{ width: '32px', height: '32px', mr: -1 }}
                            />
                          </Box>

                          <Menu
                            id={`filter-menu${index}`}
                            anchorEl={anchorEl?.anchor}
                            open={anchorEl?.id === `filter-menu${index}`}
                            onClose={() => setAnchorEl(undefined)}
                          >
                            <Box>
                              <col.filter.component
                                key={`${col.title}-${index}`}
                                name={col.filter.name}
                                formName={col.name !== undefined ? col.name : undefined}
                                value={filters.find((f) => f.name === col.filter?.name)?.value}
                                columnId={col.id ? col.id : undefined}
                                onChange={handleFilterChange}
                              />
                            </Box>
                          </Menu>
                        </>
                      )}
                      {!col.filter && <>{getTitle(col.title)}</>}

                      {col.sortingKey !== undefined &&
                        col.sortingKey !== null &&
                        getSortableIcon(
                          col.sortingKey,
                          col.id ? col.id : null,
                          col.name ? col.name : null,
                          col.filter !== undefined ? col.filter.name : null
                        )}
                    </Box>
                  </Tooltip>
                </Box>
              );
            }
          })}
        </Box>
      )}
    </>
  );
}
