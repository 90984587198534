import { format, getYear, getMonth } from 'date-fns';
import { useState, useEffect, ChangeEvent } from 'react';

import {
  Box,
  Card,
  Switch,
  useTheme,
  CardHeader,
  Typography,
  CardContent,
  FormControlLabel,
} from '@mui/material';

import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { Analytics } from 'src/@types/analytics/types';
import dashboardB2BAnalyticsService from 'src/services/dasboardB2BAnalyticsService';

import DoughnutChart from 'src/sections/dashboard/b2b/dashboard-b2b-analytics/DoughnutChart';

import { BorderLinearProgress } from './BorderLinearProgress';
import DashboardSectionHeader from '../../DashboardSectionHeader';

// ----------------------------------------------------------------------

const defaultData = {
  signatureSmartId: 0,
  signatureMobileId: 0,
  authenticationSmartId: 0,
  authenticationMobileId: 0,
  pdf: 0,
  adoc: 0,
  asice: 0,
  edoc: 0,
  bdoc: 0,
};

export default function DashboardB2BAnalytics() {
  const { t } = useLocales();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const isMobile = useResponsive('down', 'sm');
  const [analyticsCounts, setAnalyticsCounts] = useState<Analytics>(defaultData);
  const [userAnalytics, setUserAnalytics] = useState<Analytics>(defaultData);
  const [adminAnalytics, setAdminAnalytics] = useState<Analytics>(defaultData);
  const { user } = useAuth();
  const currentMonth = getMonth(new Date()) + 1;
  const monthText = t(`dashboard.month.${currentMonth}`);
  const currentYear = getYear(new Date());
  const [checked, setChecked] = useState(false);

  const date = new Date();
  const firstDay = `${format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd')}T00:00:00`;
  const lastDay = `${format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd')}T23:59:59`;

  const totalSignature = analyticsCounts.pdf + analyticsCounts.adoc + analyticsCounts.asice + analyticsCounts.edoc + analyticsCounts.bdoc;

  const rangeDate = {
    startDate: firstDay,
    endDate: lastDay,
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if(checked){
      setAnalyticsCounts(adminAnalytics);
    } else {
      setAnalyticsCounts(userAnalytics);
    }
  }, [checked]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const fetchData = async () => {
    if (user !== null && user !== undefined) {
      const data = await dashboardB2BAnalyticsService.getSignatureAuthFilesCounts(
        user.email,
        rangeDate
      );
      setAnalyticsCounts(data);
      setUserAnalytics(data);

      if(user.isAdmin){
        const dataTen = await dashboardB2BAnalyticsService.getTenantStatistics(rangeDate);
        setAdminAnalytics(dataTen);
        setChecked(true);
      } 
    }
  };

  const linearDataText = (color: string, text: string, count: number) => {
    const circle = (color: string) => (
      <Box sx={{ background: color, height: 10, width: 10, borderRadius: 5, mr: 2 }} />
    );
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {circle(color)}
        <Typography variant="body2" component="div" color="text.secondary">
          {text} - {count}
        </Typography>
      </Box>
    );
  };

  const percent = (total: number, num: number): number => {
    if (total !== 0 && num !== 0) {
      return Math.round((num * 100) / total);
    }
    return 0;
  };

  const authMobId = percent(
    analyticsCounts.authenticationMobileId + analyticsCounts.authenticationSmartId,
    analyticsCounts.authenticationMobileId
  );
  const signatureMobId = percent(
    analyticsCounts.signatureMobileId + analyticsCounts.signatureSmartId,
    analyticsCounts.signatureMobileId
  );


  return (
    <Box>
      <DashboardSectionHeader localeText="dashboard.heresYourMainNumbers" />

      <Card>
        <CardHeader
          sx={{ color: theme.palette.primary.main, }}
          title={t('dashboard.inMonthYearYouSigned', {
            month: `${monthText}`,
            year: `${currentYear}`,
          })}
          action={
            <FormControlLabel
              value="end"
              control={
              <Switch 
              checked={checked} 
              onChange={handleChange} 
              defaultChecked 
              color="primary" 
              sx={{ display: user?.isAdmin ? 'flex' : 'none' }} 
              id="exv78lhi"
              />
            }
              label={t('dashboard.generalTenantData')}
              labelPlacement="end"
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: isLight ? theme.palette.grey[600] : theme.palette.grey[300],
                  fontFamily: 'inherit', 
                  whiteSpace: 'break-spaces', 
                  width: isMobile ?  '50%' : '100%',
                  display: user?.isAdmin ? 'flex' : 'none'
                },
                mr: isMobile? 0 : 5
              }}
            />
          }
        />
        <CardContent sx={{ p: 0, pr: isMobile? 0 : 3,  py: 1, display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
          
            {/* dohnut chart */}
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="body1"
                component="div"
                color="text.secondary"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                {t('dashboard.typesOfDocuments')}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  marginBottom: 2,
                  marginTop: 1,
                }}
              >
                <DoughnutChart analyticsCounts={analyticsCounts} />
              </Box>
            </Box>

              <Box  sx={{flex: 1,  }} >

                <Box sx={{ display: 'flex'}}>

                  {/* auth statistics */}
                  <Box sx={{ flex: 1 }}>
                  
                    <Typography
                      variant="body1"
                      component="div"
                      color="text.secondary"
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {t('dashboard.authentication')}
                    </Typography>

                    <Box sx={{ my: 2, mx: isMobile ? 3 : 4 }}>
                      <BorderLinearProgress variant="determinate" value={authMobId} />

                      <Box sx={{ mt: 3 }}>
                        {linearDataText(
                          theme.palette.primary.light,
                          t('dashboard.mobileId'),
                          analyticsCounts.authenticationMobileId
                        )}

                        {linearDataText(
                          theme.palette.secondary.light,
                          t('dashboard.smartId'),
                          analyticsCounts.authenticationSmartId
                        )}
                      </Box>
                    </Box>
              
                  </Box>
          
                  {/* signature statistics */}
                  <Box sx={{flex: 1}}>
                  <Typography
                    variant="body1"
                    component="div"
                    color="text.secondary"
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    {t('dashboard.signature')}
                  </Typography>

                  <Box sx={{ my: 2, mx: isMobile ? 3 : 4 }}>
                    <BorderLinearProgress variant="determinate" value={signatureMobId} />
                    
                    <Box sx={{ mt: 3 }}>
                      {linearDataText(
                        theme.palette.primary.light,
                        t('dashboard.mobileId'),
                        analyticsCounts.signatureMobileId
                      )}

                      {linearDataText(
                        theme.palette.secondary.light,
                        t('dashboard.smartId'),
                        analyticsCounts.signatureSmartId
                      )}
                    </Box>
                  </Box>
                  </Box>

                </Box>

                {/* total signatures */}
                <Box>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    color="text.secondary"
                    sx={{ display: 'flex', mx: isMobile ? 3 : 4 , mt: 4, alignItems: 'center' }}
                  >
                   { `${t('dashboard.totalSigned')} - ${totalSignature}`}
                  </Typography>
                </Box>
              </Box>
           
        
        </CardContent>
      </Card>
    </Box>
  );
}
