import qs from 'qs';

import axios from 'src/utils/httpClients/axios';
import { mapJsonArray } from 'src/utils/mapUtils';
import { getBase64FromFile } from 'src/utils/fileUtils';

import { HOST_API } from 'src/config';
import { Document } from 'src/@types/documents/document';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { DocumentInitSign } from 'src/@types/documents/documentInitSign';
import { OwnDocumentsFilter } from 'src/@types/documents/ownDocumentsFilter';
import { DocumentModuleTypes } from 'src/@types/shared/enums/documentModuleTypes';
import { ReportOwnDocumentsFilter } from 'src/@types/documents/reportOwnDocumentsFilter';
import { DocumentSignatureType } from 'src/@types/documents/enums/documentSignatureTypes';
import {
  DocumentVoid,
  DocumentReject,
  DocumentUpload,
  DocumentSetTags,
  DocumentNeedSign,
  DocumentAdocUpload,
  DocumentStatusCount,
  AccompanyingDocumentUpload,
} from 'src/@types/documents/types';

// --------------------------------------------------------

const baseUrl = `${HOST_API}documents`;
const tempFilesUrl = `${HOST_API}temp-files`;
const accDocumentsUrl = `${HOST_API}AccDocuments`;

async function getOwnDocuments(filter: OwnDocumentsFilter): Promise<PagedResponse<Document>> {
  const response = await axios.post<PagedResponse<Document>>(`${baseUrl}/get-own`, filter);

  const data = {
    total: response.data.total,
    items: mapJsonArray(response.data.items, Document),
  };

  return data;
}

async function getFile(id: number): Promise<Blob> {
  const response = await axios.get(`${baseUrl}/${id}/file`, { responseType: 'blob' });
  const blob = new Blob([response.data]);

  return blob;
}

async function getDocument(id: number): Promise<Document | null> {
  const response = await axios.get<Document>(`${baseUrl}/${id}`);
  const data = new Document().parseJson(response.data);

  return data;
}

async function createDocument(document: DocumentUpload): Promise<number> {
  const request = {
    ...document,
    file: await getBase64FromFile(document.file),
  };
  const response = await axios.post<number>(baseUrl, request);

  return response.data;
}

async function createAdocDocument(model: DocumentAdocUpload): Promise<number> {
  const response = await axios.post<number>(`${baseUrl}/create-adoc`, model);
  return response.data;
}

async function removeDocument(id: number) {
  await axios.delete(`${baseUrl}/${id}`);
}

async function hideDocument(id: number) {
  await axios.put(`${baseUrl}/${id}/hide`);
}

async function editDocumentFile(id: number, file: File) {
  const request = await getBase64FromFile(file);
  await axios.put(`${baseUrl}/${id}/edit`, request);
}

async function setDocumentTags(request: DocumentSetTags) {
  await axios.put(`${baseUrl}/${request.id}/tags`, request);
}

async function resendNotification(id: number, signerId: number) {
  await axios.post(`${baseUrl}/${id}/signers/${signerId}/resend-notification`);
}

async function getNeedSign(id: number): Promise<DocumentNeedSign> {
  const response = await axios.get<DocumentNeedSign>(`${baseUrl}/${id}/need-sign`);
  return response.data;
}

async function getInitSign(
  id: number,
  signatureType: DocumentSignatureType
): Promise<DocumentInitSign | null> {
  const response = await axios.get<DocumentInitSign>(`${baseUrl}/${id}/init-sign`, {
    params: { signatureType },
  });
  const data = new DocumentInitSign().parseJson(response.data);

  return data;
}

async function exportCsv(filter: ReportOwnDocumentsFilter): Promise<Blob> {
  const response = await axios.post<any>(`${baseUrl}/export-csv`, filter);
  const blob = new Blob([response.data]);

  return blob;
}

async function rejectDocument(request: DocumentReject) {
  await axios.put(`${baseUrl}/${request.id}/reject`, request);
}

async function voidDocument(request: DocumentVoid) {
  await axios.put(`${baseUrl}/${request.id}/void`, request);
}

async function massApproveDocuments(documentIds: number[], displayText: string) {
  const baseUrl = `${HOST_API}Documents/mass-approve`;

  const response = await axios.post(baseUrl, { documentIds, displayText, timestamp: true });

  return response.data;
}

async function getStatusCount(moduleTypes: DocumentModuleTypes[]) {
  const response = await axios.get<DocumentStatusCount[]>(`${baseUrl}/status-count`, {
    params: { moduleTypes },
    paramsSerializer: (params) => qs.stringify(params),
  });

  return response.data;
}

async function downloadFileFolder(request: string) {
  const response = await axios.get(`${baseUrl}/bulk-download?${request}`, {
    responseType: 'blob',
  });

  const fileName = response.headers['content-disposition']
    .split(';')
    .find((n: string) => n.includes('filename='))
    .replace('filename=', '')
    .trim()
    .replaceAll('"', '')
    .split('.');

  fileName.length -= 1;

  return {
    file: new File([response.data], fileName.join(''), { type: response.headers['content-type'] }),
    fileName: fileName.join(''),
    mime: response.headers['content-type'],
  };
}

async function getTempFiles(id: number) {
  const response = await axios.get(`${tempFilesUrl}/list/${id}`);

  return response.data;
}

async function getAccompanyingDocuments(id: number) {
  const response = await axios.get(`${accDocumentsUrl}?documentId=${id}`);

  return response.data;
}

async function setAccompanyingDocuments(model: AccompanyingDocumentUpload) {
  const response = await axios.post(`${accDocumentsUrl}`, model);

  return response.data;
}

async function removeAccompanyingDocument(documentId: number, accDocId: number) {
  await axios.delete(`${accDocumentsUrl}?documentId=${documentId}&accDocId=${accDocId}`);
}

async function toggleAccompanyingDocumentHasSeen(documentId: number, accDocId: number) {
  const response = await axios.post(
    `${accDocumentsUrl}/mark-as-seen?documentId=${documentId}&accDocId=${accDocId}`
  );

  return response.data;
}

const documentService = {
  getOwnDocuments,
  getFile,
  getDocument,
  createDocument,
  createAdocDocument,
  removeDocument,
  hideDocument,
  editDocumentFile,
  setDocumentTags,
  resendNotification,
  getNeedSign,
  getInitSign,
  exportCsv,
  rejectDocument,
  voidDocument,
  massApproveDocuments,
  getStatusCount,
  downloadFileFolder,
  getTempFiles,
  getAccompanyingDocuments,
  setAccompanyingDocuments,
  removeAccompanyingDocument,
  toggleAccompanyingDocumentHasSeen,
};

export default documentService;
