import INK_COLOR from './inkColor';
import cropSignatureCanvas from './cropSignatureCanvas';

// -------------- --------------------------------

const writeNameOnTheCanvas = (name: string, signatureHeight: number) => {
  const canvas = document.createElement('canvas');
  canvas.width = 9999;
  canvas.height = signatureHeight;

  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.font = `italic ${Math.round(signatureHeight / 3)}px serif`;
    ctx.fillStyle = INK_COLOR;
    ctx.fillText(name, 10, Math.round(signatureHeight / 3 + 10));

    return cropSignatureCanvas(canvas);
  }

  return null;
};

export default writeNameOnTheCanvas;
