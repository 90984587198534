import { useState } from 'react';
import { useNavigate } from 'react-router';

import { alpha } from '@mui/material/styles';
import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  Card,
  useTheme,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';

import { PATH_HR, PATH_STANDARD, PATH_ACCOUNTING } from 'src/routes/paths';

import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { getStatusString } from 'src/utils/documentUtils';

import { ValueName } from 'src/@types/shared/valueName';
import { DocumentStatusCount } from 'src/@types/documents/types';
import { ModuleTypes } from 'src/@types/module/enums/moduleTypes';
import { Permissions } from 'src/@types/auth/constants/permissions';
import { DocumentStatuses } from 'src/@types/documents/enums/documentStatuses';
import { DocumentModuleTypes } from 'src/@types/shared/enums/documentModuleTypes';

import SvgIconStyle from 'src/components/SvgIconStyle';
import ModuleCheck from 'src/components/access-checks/ModuleCheck';
import PermissionCheck from 'src/components/access-checks/PermissionCheck';

// ----------------------------------------------------------------------

const MODULE_TYPES: ValueName<DocumentModuleTypes>[] = [
  {
    value: DocumentModuleTypes.Standard,
    localeName: 'dashboard.modules.standard',
  },
  {
    value: DocumentModuleTypes.HR,
    localeName: 'dashboard.modules.hr',
  },
  {
    value: DocumentModuleTypes.Accounting,
    localeName: 'dashboard.modules.accounting',
  },
];

type DashboardB2BDocumentStatusShortcutProps = {
  status: DocumentStatuses;
  countItems: DocumentStatusCount[];
};

export default function DashboardB2BDocumentStatusShortcut({
  status,
  countItems,
}: DashboardB2BDocumentStatusShortcutProps) {
  const { t } = useLocales();
  const navigate = useNavigate();
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'sm');

  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = (moduleType: DocumentModuleTypes) => {
    sessionStorage.setItem('fromDashboard', 'true');

    switch (moduleType) {
      case DocumentModuleTypes.Standard:
        navigate(PATH_STANDARD.documents, { state: { status } });
        break;
      case DocumentModuleTypes.HR:
        navigate(PATH_HR.documents, { state: { status } });
        break;
      case DocumentModuleTypes.Accounting:
        navigate(PATH_ACCOUNTING.documents, { state: { status } });
        break;
      default:
        break;
    }
  };

  const totalCount = countItems.reduce((partialSum, a) => partialSum + a.count, 0) || 0;

  const isLight = theme.palette.mode === 'light';
  const boxShadow = `0 0 2px 0 ${alpha(isLight ? theme.palette.grey[500] : theme.palette.common.black, 0.2)}, ${theme.customShadows.z12}`;

  function ModuleLink({ moduleType }: { moduleType: ValueName<DocumentModuleTypes> }) {
    const count = countItems.find((x) => x.moduleType === moduleType.value)?.count || 0;

    return (
      <Box
        key={moduleType.value}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pr={1}
      >
        <Typography
          onClick={() => handleClick(moduleType.value)}
          variant="subtitle2"
          sx={{
            cursor: 'pointer',
            color: theme.palette.text.secondary,
            '&:hover': { color: theme.palette.text.primary },
          }}
        >
          {t(moduleType.localeName)}
        </Typography>

        {/* ---- Because documents are counted incorrectly, it is temporarily hidden ----*/}
        {/* <Typography variant="subtitle2" sx={{ color: theme.palette.text.secondary }}> */}
        {/*  {count} */}
        {/* </Typography> */}
      </Box>
    );
  }

  return (
    <Box>
      <Card>
        <Accordion
          defaultExpanded={false}
          expanded={isExpanded}
          onChange={(e, value) => setIsExpanded(value)}
        >
          <AccordionSummary
            sx={{
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
              boxShadow,
            }}
            expandIcon={<ExpandMore />}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SvgIconStyle
                src="/icons/dashboard_quickaction.svg"
                sx={{
                  width: 20,
                  height: 20,
                  flexShrink: 0,
                  mr: 1,
                  color: theme.palette.primary.dark,
                }}
              />
              <Typography
                variant="subtitle2"
                sx={{
                  color: theme.palette.text.secondary,
                  textTransform: 'uppercase',
                  lineHeight: '1.2rem',
                }}
              >
                {t(getStatusString(status))}
              </Typography>

              {/* ---- Because documents are counted incorrectly, it is temporarily hidden ----*/}
              {/* <Typography */}
              {/*  variant="subtitle2" */}
              {/*  sx={{ ml: 1, mr: 1, color: totalCount ? theme.palette.primary.main : undefined }} */}
              {/* > */}
              {/*  ({totalCount}) */}
              {/* </Typography> */}
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '16px' }}>
            <ModuleCheck module={ModuleTypes.Standard}>
              <ModuleLink moduleType={MODULE_TYPES[0]} />
            </ModuleCheck>

            <ModuleCheck module={ModuleTypes.HR}>
              <PermissionCheck permissions={[Permissions.UseHRModule]}>
                <ModuleLink moduleType={MODULE_TYPES[1]} />
              </PermissionCheck>
            </ModuleCheck>

            <ModuleCheck module={ModuleTypes.Accounting}>
              <PermissionCheck permissions={[Permissions.UseAccountingModule]}>
                <ModuleLink moduleType={MODULE_TYPES[2]} />
              </PermissionCheck>
            </ModuleCheck>
          </AccordionDetails>
        </Accordion>
      </Card>
    </Box>
  );
}
