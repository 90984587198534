import { Dayjs } from 'dayjs';
import React, { useState, useEffect } from 'react';

import { Box } from '@mui/system';
import makeStyles from '@mui/styles/makeStyles';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

import ElementLabel from './ElementLabel';
import useLocales from '../../../hooks/useLocales';
import { setErrorList } from '../../../redux/slices/formBuilder';
import validateIsEmail from '../../../@types/forms/utils/validateIsEmail';
import validateIsNumber from '../../../@types/forms/utils/validateIsNumber';
import validateIsRequired from '../../../@types/forms/utils/validateIsRequired';
import { FormElementValidationType } from '../../../@types/forms/enums/FormElementValidationType';
import { ElementProps, ErrorsListProps, ElementValidationProps } from '../../../@types/forms/types';

//-------------------------------------------------------------

type DateRangeElementProps = {
  el: ElementProps;
  pageNumberScale: number;
  isFormFillerPage: boolean;
  elementContentValue?: [Dayjs | null, Dayjs | null];
  handleDateRangeChange?: (el: ElementProps, newValue: [Dayjs | null, Dayjs | null]) => void;
};

const DateRangeElement: React.FC<DateRangeElementProps> = ({
  el,
  pageNumberScale,
  isFormFillerPage,
  elementContentValue,
  handleDateRangeChange,
}) => {
  const { t } = useLocales();
  const [value, setValue] = useState<string | undefined>(undefined);
  const [elementErrorsList, setElementErrorsList] = useState<ErrorsListProps[]>([]);

  const useStyles = makeStyles({
    root: {
      fontFamily: `${el.font}`,
      fontWeight: el.textStyle.includes('Bold') ? 'bold' : 'normal',
      fontStyle: el.textStyle.includes('Italic') ? 'italic' : 'normal',
      textDecoration: el.textDecoration === 1 ? 'underline' : '',
      color: el.color,
      fontSize: `${el.fontSize * pageNumberScale}px`,
      lineHeight: `${el.fontSize * pageNumberScale * 1.2}px`,
      textAlign: el.textAlignment === 0 ? 'left' : el.textAlignment === 1 ? 'center' : 'right',
      width: '100%',
      wordWrap: 'break-word',
      border: 'none',
      margin: 0,
      padding: 0,
    },
    placeholder: {
      fontSize: `${el.fontSize * pageNumberScale}px`,
    },
  });

  const classes = useStyles();

  let placeholder = '';

  if (!isFormFillerPage && el.dateRangeTemplate) {
    placeholder = el.dateRangeTemplate;
    placeholder = placeholder.replaceAll('[s]', 'YYYY-MM-DD');
    placeholder = placeholder.replaceAll('[e]', 'YYYY-MM-DD');
    placeholder = placeholder.replaceAll('[w]', '0');
    placeholder = placeholder.replaceAll('[c]', '0');
  }

  if (!isFormFillerPage && !el.dateRangeTemplate) {
    placeholder = 'YYYY-MM-DD - YYYY-MM-DD';
  }

  useEffect(() => {
    const data: ElementValidationProps = { el, errorMessage: '', value, elementErrorsList };
    const newErrorList: ErrorsListProps[] = [];

    if (el.required) {
      const newError = validateIsRequired({
        ...data,
        errorMessage: t('forms.validationErrorMessage.fieldRequired'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    if (el?.validations?.value === FormElementValidationType.OnlyNumbers) {
      const newError = validateIsNumber({
        ...data,
        errorMessage: t('forms.validationErrorMessage.notNumber'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    if (el?.validations?.value === FormElementValidationType.Email) {
      const newError = validateIsEmail({
        ...data,
        errorMessage: t('forms.validationErrorMessage.invalidEmailFormat'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    setElementErrorsList(newErrorList);
  }, [value]);

  useEffect(() => {
    if (elementErrorsList) {
      setErrorList({ elementID: el.id, errors: elementErrorsList });
    }
  }, [elementErrorsList]);

  useEffect(() => {
    if (elementContentValue) {
      setValue(
        elementContentValue[0] !== null && elementContentValue[1] !== null
          ? `${elementContentValue[0].format('YYYY-MM-DD')} - ${elementContentValue[1].format('YYYY-MM-DD')}`
          : undefined
      );
    }
  }, []);

  return (
    <>
      {isFormFillerPage && (
        <Box
          sx={{ fontSize: `${el.fontSize * pageNumberScale}px`, width: el.width * pageNumberScale }}
          className="date-range-bg"
          id={el.id}
        >
          <DateRangePicker
            disabled={el.isChild}
            key={el.id}
            slots={{ field: SingleInputDateRangeField }}
            slotProps={{
              textField: {
                variant: 'standard',
                size: 'small',
                fullWidth: true,
                error: elementErrorsList.length > 0,
                helperText: elementErrorsList.length > 0 ? elementErrorsList[0].errorMessage : null,
                inputProps: { className: classes.root },
              },
            }}
            defaultValue={elementContentValue}
            onChange={(newValue) => {
              if (handleDateRangeChange) {
                handleDateRangeChange(el, newValue);
                setValue(
                  newValue[0] !== null && newValue[1] !== null
                    ? `${newValue[0].format('YYYY-MM-DD')} - ${newValue[1].format('YYYY-MM-DD')}`
                    : undefined
                );
              }
            }}
          />
        </Box>
      )}

      {!isFormFillerPage && (
        <ElementLabel el={el} label={placeholder} pageNumberScale={pageNumberScale} />
      )}
    </>
  );
};

export default DateRangeElement;
