import * as Yup from 'yup';
import { useState, useEffect, ChangeEvent } from 'react';
import { Form, useFormik, FormikProvider } from 'formik';

import { Box, Stack, MenuItem, TextField } from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useFormTranslate from 'src/hooks/useFormTranslate';

import { ValidationPatterns } from 'src/utils/constants/validationPatterns';

import { Countries, CountriesUpperCase } from 'src/@types/shared/enums/countries';
import { DocumentSignatureType } from 'src/@types/documents/enums/documentSignatureTypes';

type InitialValues = {
  personalCode: string;
  countryCode: string;
  position: string;
  subdivision: string;
};

type DocumentSmartIdSignProps = {
  submitTrigger: any;
  submitValues: (value: any) => void;
  isNotPdf: boolean;
};

export default function DocumentSmartIdSign({
  submitTrigger,
  submitValues,
  isNotPdf,
}: DocumentSmartIdSignProps) {
  const { t } = useLocales();
  const defaultCountry = () => {
    const country = localStorage.getItem('loginCountry');
    return country !== null ? country.toLowerCase() : '';
  };
  const [country, setCountry] = useState<Countries | string>(defaultCountry);

  useEffect(() => {
    if (submitTrigger) {
      submitForm();
    }
  }, [submitTrigger]);

  const personalCodeSchema = Yup.object().shape({
    personalCode: Yup.string()
      .required(t('components.smartId.personalCodeRequired'))
      .min(11, t('components.smartId.personalCodeLength')),
    countryCode: Yup.string(),
    position: Yup.string().when([], {
      is: () => isNotPdf,
      then: Yup.string().required(t('shared.errors.positionRequired')),
      otherwise: Yup.string().notRequired(),
    }),
    subdivision: Yup.string().when([], {
      is: () => isNotPdf,
      then: Yup.string().required(t('shared.errors.subdivisionRequired')),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const formik = useFormik<InitialValues>({
    initialValues: { personalCode: '', countryCode: country, position: '', subdivision: '' },
    validationSchema: personalCodeSchema,

    onSubmit: (values) => {
      if (isValid) {
        submitValues({ ...values, signatureType: DocumentSignatureType.SmartId });
        resetForm();
      }
    },
  });

  const { errors, touched, submitForm, resetForm, isValid } = formik;

  useFormTranslate(formik);

  const handlePersonalCodeChange = (e: any) => {
    formik.setFieldValue(
      'personalCode',
      ValidationPatterns.presonalCodeValueFilter(e.target.value)
    );

    const countryValue = country !== '' ? country : localStorage.getItem('loginCountry');

    formik.setFieldValue('countryCode', countryValue as CountriesUpperCase);
  };
  const COUNTRIES = [Countries.Lithuania, Countries.Latvia, Countries.Estonia];

  const handleCountryCodeChange = (e: any) => {
    formik.setFieldValue('countryCode', e.target.value as Countries);
    setCountry(e.target.value as Countries);
    localStorage.setItem('loginCountry', e.target.value.toUpperCase());
  };

  const handlePositionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const position = e.target.value;
    formik.setFieldValue('position', position);
  };
  const handleSubdivisionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const subdivision = e.target.value;
    formik.setFieldValue('subdivision', subdivision);
  };

  return (
    <Box sx={{ mb: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate>
            <Stack spacing={3} id='m9mg5dvb'>
              <input type="submit" hidden />

              {isNotPdf && (
                <>
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={handlePositionChange}
                    value={formik.values.position}
                    label={t('shared.fields.position')}
                    error={Boolean(touched.position && errors.position)}
                    helperText={touched.position && errors.position}
                    size="small"
                    id="huuyLL"
                  />
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={handleSubdivisionChange}
                    value={formik.values.subdivision}
                    label={t('shared.fields.subdivision')}
                    error={Boolean(touched.subdivision && errors.subdivision)}
                    helperText={touched.subdivision && errors.subdivision}
                    size="small"
                    id="fYeNUn"
                  />
                </>
              )}

              <TextField
                variant="outlined"
                fullWidth
                select
                onChange={handleCountryCodeChange}
                value={formik.values.countryCode}
                label={t('login.fields.country')}
                size="small"
                id="0PPdul"
              >
                {COUNTRIES.map((c) => (
                  <MenuItem key={c} value={c}>
                    {t(`shared.countries.${c}`)}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                variant="outlined"
                fullWidth
                label={t('components.smartId.personalCode')}
                error={Boolean(touched.personalCode && errors.personalCode)}
                helperText={touched.personalCode && errors.personalCode}
                onChange={handlePersonalCodeChange}
                value={formik.values.personalCode}
                inputProps={{ maxLength: 12, inputMode: 'numeric' }}
                size="small"
                id="L1tbyB"
              />
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
  );
}
